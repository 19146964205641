const siteUrl = "https://whatstats.io";

module.exports = {
    siteMetadata: {
        title: "WhatStats - Discover Fun Insights from your WhatsApp Chats",
        name: "viewport",
        content: "minimum-scale=1, initial-scale=1, width=device-width",
        description:
            "Find out who's doing the most talking. Who shares the most images, gifs, audio, and videos.  Have you been chatting more or less overtime. Sentiment Analysis: Who's spreading most positivity. Word Cloud: What do you mostly talk about. Emoji Leaderboard: Who is winning the Emoji race. Most/Least Active Months, Days of week, and Hours of the Day",
        siteUrl: siteUrl,
    },
    plugins: [
        "gatsby-theme-material-ui",
        `gatsby-plugin-image`,
        `gatsby-plugin-sharp`,
        `gatsby-plugin-react-helmet`,
        `gatsby-transformer-sharp`,

        {
            resolve: "gatsby-plugin-google-fonts",
            options: {
                fonts: ["material icons", "roboto:300,400,500,700"],
            },
        },
        {
            resolve: `gatsby-plugin-s3`,
            options: {
                bucketName: "whatstats.io",
                protocol: "https",
                hostname: "www.whatstats.io",
            },
        },
        {
            resolve: `gatsby-plugin-canonical-urls`,
            options: {
                siteUrl: siteUrl,
            },
        },
        {
            resolve: "gatsby-plugin-robots-txt",
            options: {
                host: siteUrl,
                //sitemap: siteUrl + "/sitemap.xml",
                policy: [{ userAgent: "*", allow: "/" }],
            },
        },
        {
            resolve: `gatsby-plugin-google-gtag`,
            options: {
                trackingIds: [
                    "G-Z8BTDN4LP8", // Google Analytics / GA
                ],
                pluginConfig: {
                    head: false,
                    respectDNT: true,
                },
            },
        },
    ],
};
