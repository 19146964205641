import * as React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
    box: {
        overflow: "hidden",
        display: "flex",
        margin: "20px 10px 10px 10px",
    },
}));

export default function Right() {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <StaticImage
                layout="constrained"
                src="../images/sample.png"
                alt="Fun Insights from your WhatsApp Chats"
                placeholder="blurred"
            />
        </Box>
    );
}
