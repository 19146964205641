import * as React from "react";
import {
    Typography,
    Paper,
    Chip,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    MobileScreenShare,
    CloudUpload,
    Assessment,
    ArrowDropDownCircle,
} from "@material-ui/icons";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
    typoPadding: {
        padding: 10,
        //maxWidth: 400
    },
    paper: {
        padding: 20,
    },
    steps: {
        textAlign: "center",
    },
    chip: {
        margin: 5,
    },
    center: {
        textAlign: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: "bold",
    },
    green: {
        color: "#10a125",
        //padding: 10,
    },
    iconDrop: {
        color: "#10a125",
        fontSize: 25,
    },
}));

export default function HowItWorks() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box className={classes.steps}>
                <Typography
                    className={classes.green}
                    align="center"
                    component="h2"
                    variant="h4">
                    How it Works
                </Typography>
            </Box>
            <Box className={classes.steps}>
                <Chip
                    icon={<MobileScreenShare />}
                    label="Step 1. Export"
                    className={classes.chip}
                />

                <Chip
                    icon={<CloudUpload />}
                    label="Step 2. Upload"
                    className={classes.chip}
                />

                <Chip
                    icon={<Assessment />}
                    label="Step 3. Results"
                    className={classes.chip}
                />
            </Box>

            <Typography
                component="h3"
                className={classes.typoPadding}
                variant="h6">
                Detailed Steps:
            </Typography>
            <Box className={classes.typoPadding}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={
                            <ArrowDropDownCircle className={classes.iconDrop} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                            <strong>Step 1. </strong>Export WhatsApp chat as a
                            zip or txt file
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            1.1 Open the individual or group chat you’d like the
                            stats for <br />
                            1.2. Tap the contact's name or group subject at the
                            top of your screen
                            <br /> <br />
                            1.3. Find the link to 'Export Chat' at the bottom.
                            Tap it.
                            <br />
                            <StaticImage
                                src="../images/export-whatsapp-chat.png"
                                alt="Find the link to 'Export Chat' at the bottom. Tap it."
                                placeholder="blurred"
                            />
                            <br />
                            <br /> 1.4. Select Without Media. <br />
                            <StaticImage
                                src="../images/whatsapp-without-media.png"
                                alt="Select Without Media."
                                placeholder="blurred"
                            />
                            <br />
                            <br /> 1.5. Tap Save to Files <br />
                            <StaticImage
                                src="../images/whatsapp-save.png"
                                alt="Tap Save to Files"
                                placeholder="blurred"
                            />
                            <br /> <br /> 1.6. Save to Downloads Folder on your
                            phone
                            <br />
                            <StaticImage
                                src="../images/whatsapp-download.png"
                                alt="Save to Downloads Folder on your phone"
                                placeholder="blurred"
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={
                            <ArrowDropDownCircle className={classes.iconDrop} />
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            <strong>Step 2.</strong> Upload file for data
                            analysis
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            2.1. Tap <strong>Choose File</strong> in the{" "}
                            <a href="#GettingStarted">Get Started </a> section
                            and select <strong>Browse</strong> <br />
                            <StaticImage
                                src="../images/browse-whatsapp.png"
                                alt="Tap Choose File in the Get Started section and select Browse"
                                placeholder="blurred"
                            />
                            <br />
                            <br /> 2.2. From the <strong>Recents</strong> tab,
                            select the appropriate WhatsApp zip (or txt) file to
                            upload
                            <br />
                            <StaticImage
                                src="../images/whatsapp-select-recent-file.png"
                                alt="From the Recents tab, select the appropriate WhatsApp zip or txt file to upload."
                                placeholder="blurred"
                            />
                            <br />
                            <br /> 2.3. Once the file has been attached, verify
                            the Captcha and click{" "}
                            <strong>Start Analyzing</strong>
                            <br />
                            <StaticImage
                                src="../images/upload-whatsapp.png"
                                alt="Once the file has been attached, verify the Captcha and click Start Analyzing"
                                placeholder="blurred"
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={
                            <ArrowDropDownCircle className={classes.iconDrop} />
                        }
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <Typography className={classes.heading}>
                            <strong>Step 3.</strong> See Results in 5-10 seconds
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Tap on the link to navigate to the results page{" "}
                            <br />
                            <StaticImage
                                src="../images/whatsapp-results-whatstats.png"
                                alt="Results are available in 5-10 seconds on your unique
                            link"
                                placeholder="blurred"
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Paper>
    );
}
