module.exports = {
    title: "WhatStats: Fun Insights from your WhatsApp Chats",
    description:
        "Find out who's doing the most taking. Who shares the most images, gifs, audio, and videos.  Have you been chatting more or less overtime. Sentiment Analysis: Who's spreading most positivity. Word Cloud: What do you mostly talk about. Emoji Leaderboard: Who is winning the Emoji race. Most/Least Active Months, Days of week, and Hours of the Day",
    url: "https://whatstats.io",
    demoUrl: "https://my.whatstats.io/?q=demo",
    discordServer: "https://discord.gg/jkyg3gQwEY",
    api: {
        checkResults: "https://data.whatstats.io/checkresults?key=",
        getSignedS3Uri: "https://data.whatstats.io/signs3",
        logError: "https://data.whatstats.io/error"
    }
};
