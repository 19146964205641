import * as React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import config from "../config";

const useStyles = makeStyles(() => ({
    topnav: {
        paddingTop: 10,
        fontWeight: "bold",
        textAlign: "center",
    },
}));

export default function TopNav(props) {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={4}>
                <a
                    href={config.demoUrl}
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    <Typography
                        component="h2"
                        variant="subtitle2"
                        className={classes.topnav}>
                        See Demo
                    </Typography>
                </a>
            </Grid>
            <Grid item xs={4}>
                <a href="#HowItWorks">
                    <Typography
                        component="h2"
                        variant="subtitle2"
                        className={classes.topnav}>
                        How it Works
                    </Typography>
                </a>
            </Grid>
            <Grid item xs={4}>
                <a href="#GettingStarted">
                    <Typography
                        component="h2"
                        variant="subtitle2"
                        className={classes.topnav}>
                        Get Started
                    </Typography>
                </a>
            </Grid>
        </Grid>
    );
}
