import * as React from "react";
import { Typography, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    },

    green: {
        color: "#10a125",
    },
}));

export default function Privacy() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box className={classes.steps}>
                <Typography
                    className={classes.green}
                    align="center"
                    component="h6"
                    variant="h6">
                    Data &amp; Privacy
                </Typography>
            </Box>

            <Typography component="div" variant="body1">
                <ul>
                    <li>
                        There are no user registrations, user logins, or browser
                        cookies to collect or track any user information. You
                        and I will always be #StrangersForever (wish other apps
                        did this)
                    </li>
                    <li>
                        The data analysis happens in memory and all data is
                        automatically deleted immediately post analysis. No
                        humans read the uploaded data
                    </li>
                    <li>
                        The user insights are secured by a unique URL. This link
                        is deactivated after 7-days
                    </li>
                    <li>
                        There are no ads or 3rd party players snooping on any
                        user data (there is no data to share anyway)
                    </li>
                </ul>
            </Typography>
           
        </Paper>
    );
}
