import * as React from "react";
import { CssBaseline, Typography, Grid, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HowItWorks from "../components/howItWorks";
import Privacy from "../components/privacy";
import Footer from "../components/footer";
import Right from "../components/right";
import Helmet from "../components/helmet";
import { StaticImage } from "gatsby-plugin-image";
import Left from "../components/left";
import GettingStarted from "../components/start";
import "../components/style.css";
import TopNav from "../components/topNav";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        minHeight: 140,
        minWidth: 100,
        //padding: 20,
        margin: 20,
    },
    title: {
        paddingTop: 40,
    },
    box: {
        margin: "0 5% 5% 5%",
    },
    hide: {
        visibility: "hidden",
    },
}));

export default function MyApp() {
    const classes = useStyles();

    return (
        <>
            <Helmet />
            <CssBaseline />
            <main>
                <Paper>
                    <Grid justify="center" container style={{ padding: 20 }}>
                        <Grid item>
                            <a href="/">
                                <StaticImage
                                    layout="constrained"
                                    src="../images/WhatStats_Logo_S.png"
                                    alt="WhatStats"
                                    width={40}
                                />
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="/">
                                <Typography
                                    align="center"
                                    style={{ color: "#10a125" }}
                                    component="h2"
                                    variant="h4">
                                    WhatStats
                                </Typography>
                            </a>
                        </Grid>
                        <TopNav />
                    </Grid>
                </Paper>

                <Grid container className={classes.root} justify="center">
                    <Grid item xs={12} sm={6}>
                        <Left />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Right />
                    </Grid>
                </Grid>

                <Box className={classes.box}>
                    <a
                        id="GettingStarted"
                        className={classes.hide}
                        aria-label="Get Started"
                        href="/">
                        Get Started
                    </a>

                    <GettingStarted />

                    <a
                        id="HowItWorks"
                        className={classes.hide}
                        aria-label="How It Works"
                        href="/">
                        HowItWorks
                    </a>

                    <HowItWorks />
                    <a
                        id="Privacy"
                        style={{ visibility: "hidden" }}
                        aria-label="Privacy"
                        href="/">
                        Privacy
                    </a>
                    <Privacy />
                </Box>
            </main>
            <Footer />
        </>
    );
}
