import * as React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
        margin: "20px 10px 0 20px",
    },
    typoPadding: {
        paddingBottom: 10,
    },
}));

export default function Left() {
    const classes = useStyles();

    return (
        <Box className={classes.paper}>
            <Typography
                align="center"
                className={classes.typoPadding}
                component="h1"
                variant="h4">
                Discover Fun Insights from your WhatsApp Chats
            </Typography>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Who's doing the most talking"
                        variant="h6">
                        📣
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        Who's doing the <strong>most talking</strong>?
                    </Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Media Leaderboard"
                        variant="h6">
                        🏆
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong>Media Leaderboard:</strong> Who shares the most
                        images, gifs, audio, and videos?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Emoji"
                        variant="h6">
                        📈
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong> Conversation Trend:</strong> Have you been
                        chatting more or less overtime?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Emoji"
                        variant="h6">
                        🤗
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong> Sentiment Analysis:</strong> Who's spreading
                        most positivity?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Emoji"
                        variant="h6">
                        💬
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong>Word Cloud:</strong> What do you mostly talk
                        about?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Emoji"
                        variant="h6">
                        👑
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong>Emoji Leaderboard: </strong>Who is winning the
                        Emoji race?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Typography
                        component="span"
                        role="img"
                        aria-label="Emoji"
                        variant="h6">
                        📅
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typoPadding}
                        component="h2"
                        variant="body1">
                        <strong>Most/Least Active </strong> Months, Days of
                        week, and Hours of the Day
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
