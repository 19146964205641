import React from "react";
import { Typography, Box, Paper, Input, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import config from "../config";

const useStyles = (theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
    typoPadding: {
        padding: 20,
        //maxWidth: 400
    },
    paper: {
        //padding: 40,
    },
    box: {
        justifyContent: "center",
        display: "flex",
        padding: 10,
    },
    dontbreakout: {
        overflowWrap: "break-word",
        wordWrap: "break-word",
        msWordBreak: "break-all",
        wordBreak: "break-all",
        // wordBreak: "break-word",
        msHyphens: "auto",
        mozHyphens: "auto",
        webkitHyphens: "auto",
        hyphens: "auto",
        justifyContent: "center",
        display: "flex",
        padding: 10,
    },
    green: {
        color: "#10a125",
        padding: 20,
    },
    greenOnly: {
        color: "#10a125",
        padding: "0 4px",
    },
});

const STATUS_ENUM = {
    INITIAL: "initial",
    IN_PROGRESS: "in_progress",
    DATA_IS_HERE: "data_is_here",
};

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enableButton: false,
            uniqueUrl: null,
            capcha: null,
            file: null,
            status: STATUS_ENUM.INITIAL,
            statusMsg: null,
            statusMsgColor: STATUS_ENUM.INITIAL,
        };
        this.handleAttachment = this.handleAttachment.bind(this);
        this.captchaChange = this.captchaChange.bind(this);
        this.captchaError = this.captchaError.bind(this);
        this.submit = this.submit.bind(this);
        this.startOver = this.startOver.bind(this);
    }

    captchaChange(event) {
        let baseState = {
            capcha: event,
            enableButton: false,
        };
        if (Boolean(this.state.file)) {
            baseState.enableButton = true;
        }
        this.setState(() => baseState);
    }

    handleAttachment(event) {
        let error = {
            statusMsgColor: "error",
            statusMsg: null,
            file: null,
            enableButton: false,
            status: STATUS_ENUM.INITIAL,
        };
        const file = event.target.files[0];
        if (file.type.indexOf("zip") > 0 || file.type.indexOf("text") >= 0) {
            if (file.size < 10000000) {
                let baseState = {
                    file: file,
                    statusMsg: null,
                    status: STATUS_ENUM.INITIAL,
                    enableButton: false,
                    statusMsgColor: STATUS_ENUM.INITIAL,
                };
                if (Boolean(this.state.capcha)) {
                    baseState.enableButton = true;
                }
                this.setState(() => baseState);
            } else {
                error.statusMsg =
                    "Are you sure you selected the 'Without Media' option when exporting from WhatsApp?";
                this.setState(() => error);
            }
        } else {
            error.statusMsg =
                "Please only upload the .zip or .txt file generated by your WhatsApp app.";
            this.setState(() => error);
        }
    }

    captchaError() {
        this.setState(() => ({
            capcha: null,
            enableButton: false,
        }));
    }

    startOver() {
        this.setState(() => ({
            capcha: null,
            enableButton: false,
            statusMsg: null,
            statusMsgColor: STATUS_ENUM.INITIAL,
            status: STATUS_ENUM.INITIAL,
        }));
    }

    async submitTest() {
        const res = await axios.post(
            config.api.getSignedS3Uri,
            { key: "key" },
            {
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    authorization: "capcha",
                },
            }
        );
        //console.log(res);
    }

    async submit() {
        if (!this.state.enableButton) return null;

        this.setState(() => ({
            progress: true,
            status: STATUS_ENUM.IN_PROGRESS,
            statusMsg: "Uploading... ☁️",
        }));

        const res = await axios.post(
            config.api.getSignedS3Uri,
            { key: this.state.file.name },
            {
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    Authorization: this.state.capcha,
                },
            }
        );

        if (res.status === 200) {
            this.setState(() => ({
                statusMsg: "Extracting... 🚪",
            }));
            console.log(res.data.key)

            const s3SignedUrl = res.data.url;
            const s3Response = await axios.put(s3SignedUrl, this.state.file);
            //console.log(s3Response);

            if (
                s3Response.statusMsgText === "OK" ||
                s3Response.statusText === "OK"
            ) {
                //console.log("File Uploaded. Let the parsing begin");
                this.setState(() => ({
                    statusMsg: "Analyzing... 🔍",
                }));

                const resultStatus = await getResults(
                    res.data.key,
                    this.state.capcha
                );

                if (resultStatus === 200) {
                    this.setState(() => ({
                        uniqueUrl: "https://my.whatstats.io/?q=" + res.data.key,
                        status: STATUS_ENUM.DATA_IS_HERE,
                    }));
                } else {
                    this.setState(() => ({
                        capcha: null,
                        enableButton: false,

                        statusMsg: "Something went wrong. Perhaps try again?",
                        statusMsgColor: "error",
                        status: STATUS_ENUM.INITIAL,
                    }));
                }
            }
        }
    }

    render() {
        //console.log(this.state);
        const { classes } = this.props;
        const clickHereForInstructions = (
            <>
                Click
                <a href="#HowItWorks">
                    <Typography component="span" className={classes.greenOnly}>
                        here
                    </Typography>
                </a>
                for instructions
            </>
        );
        let screen = null;

        if (this.state.status === STATUS_ENUM.DATA_IS_HERE) {
            screen = (
                <>
                    <Box className={classes.box}>
                        <Typography component="h6" variant="h6">
                            Tap this link to view your WhatStats Results 📊
                        </Typography>
                    </Box>
                    <Box className={classes.dontbreakout}>
                        <a
                            href={this.state.uniqueUrl}
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            <Typography
                                component="div"
                                variant="subtitle2"
                                className={classes.green}>
                                {this.state.uniqueUrl}
                            </Typography>
                        </a>
                    </Box>
                    <Box className={classes.box}>
                        <Typography align="center" variant="caption">
                            Note: This link will remain active for 7 days.
                        </Typography>
                    </Box>
                    <Box className={classes.box}>
                        <Button variant="contained" onClick={this.startOver}>
                            Start Over
                        </Button>
                    </Box>
                </>
            );
        } else if (this.state.status === STATUS_ENUM.IN_PROGRESS) {
            screen = (
                <Box className={classes.box}>
                    <Typography component="h6" variant="h6">
                        {this.state.statusMsg}
                    </Typography>
                </Box>
            );
        } else {
            screen = (
                <>
                    <Box className={classes.box}>
                        <Typography
                            color={this.state.statusMsgColor}
                            variant="body1">
                            {this.state.statusMsg}
                            <br />
                            {clickHereForInstructions}
                        </Typography>
                    </Box>

                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off">
                        <Box className={classes.box}>
                            <Input
                                id="myfile"
                                type="file"
                                onChange={this.handleAttachment}
                                placeholder="Default Value"
                                accept=".zip"
                            />
                        </Box>
                        <Box className={classes.box}></Box>
                        <Box className={classes.box}>
                            <ReCAPTCHA
                                sitekey="6Lf3MugaAAAAALlPtnPneSJgS6d7O-r1WTvsaDiN"
                                onChange={this.captchaChange}
                                onExpired={this.captchaError}
                                onErrored={this.captchaError}
                            />
                        </Box>
                        <Box className={classes.box}>
                            <Button
                                disabled={!this.state.enableButton}
                                variant="contained"
                                style={{
                                    backgroundColor: this.state.enableButton
                                        ? "#10a125"
                                        : "",
                                    color: "#fff",
                                }}
                                onClick={this.submit}>
                                Start Analyzing
                            </Button>
                        </Box>
                    </form>
                </>
            );
        }
        return (
            <Paper className={classes.paper}>
                <Typography
                    className={classes.green}
                    align="center"
                    component="h2"
                    variant="h4">
                    Get Started
                </Typography>
                {screen}
            </Paper>
        );
    }
}

async function getResults(key, header) {
    return new Promise((resolve, reject) => {
        let recursiveChecking;
        const maxRetries = 20;
        let retryCount = 1;
        const apiKey = "ewajU8DEKz32eNlvcEV2V2OuGOnmPFgL4Gfv6Zzv";

        const keepCheckingRecursively = async function () {
            try {
                let results = await axios.get(config.api.checkResults + key, {
                    headers: {
                        Peepli: btoa(new Date().toUTCString()),
                        "x-api-key": apiKey,
                    },
                });

                if (results.status === 202 && retryCount < maxRetries) {
                    retryCount++;
                    recursiveChecking = setTimeout(function () {
                        keepCheckingRecursively();
                    }, 1000);
                } else if (results.status === 200) {
                    clearTimeout(recursiveChecking);
                    resolve(200);
                } else {
                    await axios.post(
                        config.api.logError,
                        { key: key, error: `Retried ${retryCount} times` },
                        {
                            headers: {
                                "content-type":
                                    "application/x-www-form-urlencoded",
                                Peepli: btoa(new Date().toUTCString()),
                                "x-api-key": apiKey,
                            },
                        }
                    );
                    resolve(403);
                }
            } catch (e) {
                //console.log("logging error");
                await axios.post(
                    config.api.logError,
                    { key: key, error: `Retried ${retryCount} times` },
                    {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded",
                            Peepli: btoa(new Date().toUTCString()),
                            "x-api-key": apiKey,
                        },
                    }
                );
                resolve(403);
            }
        };
        keepCheckingRecursively();
    });
}

export default withStyles(useStyles)(Start);
