import * as React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";
import image from "../images/WhatStats_Logo_S.png";
import config from "../../gatsby-config";

export default function MyHelmet() {
    const title = config.siteMetadata.title;
    const url = config.url;
    const description = config.siteMetadata.description;
    const user = "@AroraGary";
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
            <meta name="image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={user} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    );
}
